import React from 'react';
import { withRouter } from '../withRouter';
import {Link} from "react-router-dom"

import { TiArrowSortedUp } from "react-icons/ti"
import { FaDiscord, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { AiOutlineMail } from "react-icons/ai"

import ApiContext from '../Tools/ApiClass';


import "../Styles/Header.scss"

class LookingForJob extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            dropdownActive: false,
            content:[],
            instagram:[],
            linkedin:[],
            discord:[],
            mail:[],
        }
    }
    
    componentDidMount = async () => {
        this.setState({
            content: await new ApiContext().GetContent("job"),
            instagram: await new ApiContext().GetContent("social-instagram"),
            linkedin: await new ApiContext().GetContent("social-linkedin"),
            discord: await new ApiContext().GetContent("social-discord"),
            mail: await new ApiContext().GetContent("social-mail"),
        },()=>{})
    }

    toggleDropdown = () => {
        this.setState({dropdownActive:!this.state.dropdownActive})
    }

    render() {
        let className = this.state.dropdownActive?" active":" ";
        if(this.state.content.enabled == "0"){return;}
        return(
            <div id="Looking-for-job" className={this.props.darkmode + className}>
                <div className="header" onClick={this.toggleDropdown}>
                    <TiArrowSortedUp className={className} />
                    <span>I'm looking for new opportunities !</span>
                    <TiArrowSortedUp className={className} />
                </div>
                <div className="content" onClick={this.toggleDropdown} dangerouslySetInnerHTML={{__html :this.state.content.content}} />
                <div className="footer">
                    <a target='_blank' href={this.state.discord.content} ><FaDiscord /></a>
                    <a target='_blank' href={this.state.instagram.content} ><FaInstagram /></a>
                    <a target='_blank' href={this.state.linkedin.content} ><FaLinkedinIn /></a>
                    <a target='_blank' href={this.state.mail.content} ><AiOutlineMail /></a>
                </div>
            </div>
        )
    }
}

export default withRouter(LookingForJob);