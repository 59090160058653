import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Home.scss"

import AppContext from '../Tools/AppContext';



class TagsManager extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            checkbox : [],
            allCheckBox : false,
            tagsData: []
        }
    }
    
    componentDidUpdate = () => {
        if(this.props.tags != this.state.tagsData){
            this.setState({tagsData:this.props.tags})
            let states = {}
            for(var item in this.props.tags){
                states[this.props.tags[item].id] = false
            }
            this.setState({checkbox: states}, () => {
                setTimeout(()=>{
                    this.toggleAllCheckbox()
                },"1")
            });
        }
    }

    toggleCheckbox = (event) => {
        let newCheckbox = this.state.checkbox
        newCheckbox[event.target.dataset.state] = !newCheckbox[event.target.dataset.state];
        this.setState({checkbox : newCheckbox,}, () => {this.findCheckedId()})
        
    }

    toggleAllCheckbox = () => {

        let value = !this.checkIfAllChecked()
        let checkbox = this.state.checkbox
        Object.keys(checkbox).forEach(element => {
            checkbox[element] = value;
        });
        this.setState({checkbox:checkbox},()=>{this.findCheckedId()})
    }


    checkIfAllChecked = () => {
        for(var checked in this.state.checkbox){
            if(this.state.checkbox[checked] == false){
                return false
            }
        }
        return true
    }

    findCheckedId = () => {
        let tags_array = []
        Object.keys(this.state.checkbox).forEach(element => {
            if(this.state.checkbox[element] == true){
                tags_array.push(element)
            }
        });
        if(this.checkIfAllChecked()){
            tags_array = []
        }
        this.props.setTags(tags_array)
    }

    render() {
        let display = []
        if(this.state.checkbox == null){return;}
        display.push(
            <div key={"not-cool"}>
                <label key={"omgIdontliketa"}>
                    <input key={"realyydontlike"} type="checkbox" checked={this.checkIfAllChecked()} onChange={(event) => this.toggleAllCheckbox(event)} />
                    <span>All</span>
                </label>
                <span className="separator">|</span>
            </div>
        )
        for(var item in this.props.tags){
            display.push(
                <label key={this.props.tags[item].id + this.props.tags[item].nom+"checkbox_tags"}>
                    <input key={this.props.tags[item].id + this.props.tags[item].nom+"checkbox_tags_input"} type="checkbox" data-state={this.props.tags[item].id} checked={this.state.checkbox[this.props.tags[item].id]?this.state.checkbox[this.props.tags[item].id]:false} data-id={this.props.tags[item].id} onChange={(event) => this.toggleCheckbox(event)} />
                    <span style={{backgroundColor:this.props.color?this.props.tags[item].color:null}}>{this.props.tags[item].nom}</span>
                    <div className="decorator" />
                </label>
            )
        }
        return display
    }
}

export default withRouter(TagsManager);