import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Projects.scss"

import AppContext from '../Tools/AppContext';

import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs"
import { Link } from 'react-router-dom';
import TagsManager from '../Components/TagsManager';
import Project from './Project';
import ApiContext from '../Tools/ApiClass';


class Projects extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props)
        this.state = {
            tags_id: [],
            id: -1,
            dataSpotlight:[],
            dataCategory:[],
            dataProject:[],
            dataTabProject:[],
            dataAbout:[],
        }
    }

    componentDidMount = () => {
        this.fetchSpotlight();
        this.fetchCategories();
        this.fetchAbout();
        
    }

    fetchAbout = async () => {
        let data = await new ApiContext().GetContent("about_project");
        this.setState({dataAbout:data})
    }

    fetchSpotlight = async () => {
        let data = await new ApiContext().GetDevSpotlightProjects()
        this.setState({dataSpotlight:data})
    }

    fetchCategories = async () => {
        let data = await new ApiContext().GetCategories()
        this.setState({dataCategory:data})
    }

    fetchProjects = async () => {
        let data = await new ApiContext().GetDevProjects(this.state.tags_id)
        this.setState({dataProject:data})
    }

    setTags = (tags) => {
        this.setState({
            tags_id: tags
        },()=>{
            this.fetchProjects()

        })
    }

    loadArticleData = async (id) => {
        if(id == -1){ return }
        this.state.dataProject.forEach(element => {
            if(id == element.id){
                this.setState({dataTabProject:element})
                return;
            }
        });

    }

    openArticleEvent = (event) => {
        this.setState({id:event.currentTarget.dataset.id})
        this.loadArticleData(event.currentTarget.dataset.id)
    }

    openArticle = (id) => {
        this.setState({id:id})
        this.loadArticleData(id)
    }

    render() {
        return (
            <>
                <div id="Projects" className={this.props.darkmode + " main-pages"}>
                    <div className="side"></div>
                    <div className="container">
                        <div className="header">
                            <h1>Projects</h1>
                        </div>
                        <div className="highlighted-projects">
                            <div className="carousel-container">
                                <h2>Spotlight </h2>
                                <div className="carousel-scroll" id="Carousel-highlight" onScroll={(e) => { console.log(e.target.scrollLeft) }}>
                                    {this.renderSpotlight()}
                                </div>
                            </div>
                            <div className="about">
                                <h2>{this.state.dataAbout.titre}</h2>
                                <p>{this.state.dataAbout.content}</p>
                            </div>
                        </div>
                        <div className="all-projects">
                            <h2>All projects</h2>
                            <div className="tags">
                                <TagsManager tags={this.state.dataCategory} setTags={this.setTags} />
                            </div>
                            <div className="project-galery">
                                {this.renderGalery()}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderSpotlight = () => {
        let display = []
        Object.keys(this.state.dataSpotlight).forEach(element => {
            display.push(
                <Link to={"/project/"+this.state.dataSpotlight[element].id} onClick={(e) => this.openArticleEvent(e)} data-id={this.state.dataSpotlight[element].id} className="carousel-item" key={element}>
                    <div className="text-holder">
                        <span className="title">{this.state.dataSpotlight[element].nom}</span>
                        <div className="tags">
                            {this.state.dataSpotlight[element].category.map(e => <span key={e.nom+"tagsid"+this.state.dataSpotlight[element].nom+"spotlight"} style={{ backgroundColor: e.color }}>{e.nom}</span>)}
                        </div>
                    </div>
                    <div className="background-img" style={{ backgroundImage: "url(" + "'" + this.state.dataSpotlight[element].url_thumbnail + "'" + ")" }} />
                </Link>
            )
        });
        return display;
    }

    renderGalery = () => {
        let display = []
        if(this.state.dataProject.length == 0){
            return;
        }
        Object.keys(this.state.dataProject).forEach(project => {
            if(this.state.dataProject[project].enable == 0){
                return;
            }
            display.push(
                <Link to={"/project/"+this.state.dataProject[project].id} onClick={(e) => this.openArticleEvent(e)} data-id={this.state.dataProject[project].id} key={this.state.dataProject[project].id+"project-key"} className="project-item">
                    <div className="absolute-tag"></div>
                    <div className="content-project">
                        <div className="content-main">
                            <span className="title">{this.state.dataProject[project].nom}</span>
                            <span className="description">{this.state.dataProject[project].description}</span>
                        </div>
                        <div className="tags-main">
                            <span className="tags-title">Tags</span>
                            <div className="tags-container">
                                {this.state.dataProject[project].categories.map(e => <span style={{backgroundColor:e.color}} key={this.state.dataProject[project].id+ "tags"+e.nom}>{e.nom}</span>)}
                            </div>
                        </div>
                    </div>
                    
                </Link>
            )
        })
        return display
    }
}

export default withRouter(Projects);


