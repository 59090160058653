import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Arts.scss"

import AppContext from '../Tools/AppContext';
import ApiContext from '../Tools/ApiClass';


import Art from "./Art"
import ArtTab from "../Components/ArtTab"
import AboutArtTab from "../Components/AboutArtTab"

class Arts extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props)
        this.state = {
            currentTab: "Art",
            id:-1,
            data:[],
        }
        this.tabs = {
            "Art": ArtTab,
            "About": AboutArtTab
        }

    }

    changeTab = (event) => {
        this.setState({ currentTab: event.target.closest(".tab").dataset.id })
    }

    openPage = async (id) => {
        if(id!=-1){
            await this.fetchProject(id)
        }

        this.setState({id : id}, () => {
        });
    }

    fetchProject = async (id) => {
        let data = await new ApiContext().GetArtProject(id)
        this.setState({data:data})
    }

    generateMenu = () => {
        let display = []
        let i = 0;
        for (var element in this.tabs) {
            display.push(
                <span key={element} className={(this.state.currentTab == element ? "active" : " ") + " tab" + " tab-" + i} data-id={element} onClick={(event) => this.changeTab(event)}>{element}</span>
            )
            i++
        }
        return display
    }

    render() {
        return (
            <>
                <div id="Arts" className={this.props.darkmode + " main-pages"}>
                    <div className="side"></div>
                    <div className="container">
                        <div className="header">
                            <h1>Artfolio</h1>
                        </div>
                        <div className="tabs-parent">
                            {
                                this.generateMenu()
                            }
                        </div>
                        {
                            this.renderTabs()
                        }
                    </div>
                </div>
                <Art darkmode={this.props.darkmode} id={this.state.id} data={this.state.data} openPage={this.openPage}/>
            </>
        )
    }
    renderTabs = () => {
        return React.createElement(this.tabs[this.state.currentTab], { openPage : this.openPage, ...this.props })
    }
}

export default withRouter(Arts);