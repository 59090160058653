import axios from 'axios';

class ApiContext{
    constructor(){
        this.api_url = "https://api.4lexis.fr/"
    }

    GetDevSpotlightProjects = async () => {
        let data = await axios({
            method:'post',
            url:this.api_url+'get-spotlight',
            data:{

            }
        })
        .then((res)=>{
            return res.data
        })
        return data
    }
    GetCategories = async () => {
        let data = axios({
            method:'post',
            url:this.api_url+'get-categories',
            data:{

            }
        })
        .then((res)=>{
            return res.data
        })
        return data
    }
    GetDevProjects = async (categories) => {
        let data = await axios({
            method:'post',
            url:this.api_url+'get-projects',
            data:{
                categories:categories
            }
        })
        .then((res)=>{
            return res.data
        })
        return data;
    }
    GetDevProject = async (id) => {
        let data = await axios({
            method:'post',
            url:this.api_url+'get-project',
            data:{
                id:id
            }
        })
        .then((res)=>{
            return res.data
        })
        return data;
    }
    GetArtCategories = async () => {
        let data = await axios({
            method:'post',
            url:this.api_url+'get-art-categories',
        })
        .then((res)=>{
            return res.data
        })
        return data;
    }

    GetArtProjects = async (categories) => {
        let data = await axios({
            method:'post',
            url:this.api_url+'get-art-projects',
            data:{
                categories:categories
            }
        })
        .then((res)=>{
            return res.data
        })
        return data;
    }

    GetArtProject = async (id) => {
        let data = await axios({
            method:'post',
            url:this.api_url+'get-art-project',
            data:{
                id:id
            }
        })
        .then((res)=>{
            return res.data
        })
        return data;
    }

    GetContent = async (title) => {
        let data = await axios({
            method:'post',
            url:this.api_url+'get-content',
            data:{
                title:title
            }
        })
        .then((res)=>{
            return res.data
        })
        return data;
    }
}

export default ApiContext;