import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Home.scss"

import AppContext from '../Tools/AppContext';

import { FaDiscord, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { AiOutlineMail } from "react-icons/ai"

import {Link} from "react-router-dom"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ApiContext from '../Tools/ApiClass';


class Home extends React.Component{
    static contextType = AppContext;
    constructor(props){
        super(props)
        this.state = {
            content: [],
            instagram:[],
            linkedin:[],
            discord:[],
            mail:[],
        }
    }

    componentDidMount = async () => {
        this.setState({
            content: await new ApiContext().GetContent("subtitle-home"),
            instagram: await new ApiContext().GetContent("social-instagram"),
            linkedin: await new ApiContext().GetContent("social-linkedin"),
            discord: await new ApiContext().GetContent("social-discord"),
            mail: await new ApiContext().GetContent("social-mail"),
        },()=>{})
    }

    render() {
        return(
            <div id="Home" className={this.props.darkmode}>
                <NotificationContainer />
                <div className="main-content">
                    <div className="centered-content">
                        <div className="personnal-info">
                            <h1>Alexis FAURE</h1>
                            <h3>{this.state.content.content}</h3>
                        </div>
                        <div className={this.props.darkmode + " navigate"}>
                            <span className="title">Navigation</span>
                            <nav>
                                <Link to="/projects" className="nav-link"><span className="number">1</span><span className="span-link">DEV PROJECTS</span></Link>
                                <Link to="/arts" className="nav-link"><span className="number">2</span><span className="span-link">ARTS</span></Link>
                                <Link to="/profile" className="nav-link"><span className="number">3</span><span className="span-link">PROFILE</span></Link>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="right-side-content">
                    <div className={this.props.darkmode + " social-media"} >
                        <a target="_blank" href={this.state.discord.content} className="media"><span>Discord</span><FaDiscord/></a>
                        <a target="_blank" href={this.state.linkedin.content} className="media"><span>LinkedIn</span><FaLinkedinIn/></a>
                        <a target="_blank" href={this.state.instagram.content} className="media"><span>Instagram</span><FaInstagram/></a>
                        <a onClick={()=>{
                            navigator.clipboard.writeText(this.state.mail.content)
                            NotificationManager.info("email copied !")
                        }} href={this.state.mail.content} className="media"><span>Email</span><AiOutlineMail/></a>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Home);