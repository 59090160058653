import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Profile.scss"

import AppContext from '../Tools/AppContext';

import { TiArrowSortedDown } from "react-icons/ti"
import { AiOutlineDownload } from "react-icons/ai"
import { IoMdSchool, IoLogoGameControllerB } from "react-icons/io"
import { FaLanguage } from 'react-icons/fa';


class Profile extends React.Component{
    static contextType = AppContext;
    constructor(props){
        super(props)
    }

    toggleDropdown = (e) => {
        console.log(e.target.classList)
        const parent = e.target.closest(".dropdown");
        if(parent.classList.contains("active")){parent.classList.remove("active")}else{parent.classList.add("active")}
    }

    render() {
        return(
            <div id="Profile" className={this.props.darkmode + " main-pages"}>
                <div className="side"></div>
                <div className="container">
                    <div className="header">
                        <a href="https://api.4lexis.fr/img/portefolio/CV_new_dev.pdf"><div className="download-button"><AiOutlineDownload /><span>Download my CV</span></div></a>
                        <h1>Profile</h1>
                    </div>
                    <div className="content">
                        <div className="dropdown" >
                            <div className="visible-parent" onClick={(e)=>{this.toggleDropdown(e)}}>
                                <h2><IoMdSchool />Education</h2>
                                <TiArrowSortedDown className="left-arrow" />
                            </div>
                            <div className={this.props.darkmode + " hidden-parent education"}>
                                <div className="education-child">
                                    <div className="title">
                                        <h3>Gaming Campus - Bachelor - Computer Science specialization Video Games </h3><span>2019-2023 - 3 years</span>
                                    </div>
                                    <div className="description">
                                        <p>Training 100% by project and by group, creation of video games, general knowledge (business plan, game design, law...). Professional tutor coming from the video game industry.</p>
                                    </div>
                                </div>
                                <div className="education-child">
                                    <div className="title">
                                        <h3><i>(Futur)</i> Gaming Campus - Master Of Science - Video Game Programming (Sandwich Course/Alternance) </h3><span>2023-2025 - 2 years</span>
                                    </div>
                                    <div className="description">
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown">
                            <div className="visible-parent" onClick={(e)=>{this.toggleDropdown(e)}}>
                                <h2><FaLanguage />Language</h2>
                                <TiArrowSortedDown className="left-arrow" />
                            </div>
                            <div className={this.props.darkmode + " hidden-parent language"}>
                                <div className="language-child">
                                    <span className="language-name">French</span><span className="language-level">Mother Tongue</span>
                                </div>
                                <div className="language-child">
                                    <span className="language-name">English</span><span className="language-level">B2</span>
                                </div>
                                <div className="language-child">
                                    <span className="language-name">Spanish</span><span className="language-level">B1</span>
                                </div>
                                <div className="language-child">
                                    <span className="language-name">Japanese</span><span className="language-level">A1+</span>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown">
                            <div className="visible-parent" onClick={(e)=>{this.toggleDropdown(e)}}>
                                <h2><IoLogoGameControllerB />Hobbies</h2>
                                <TiArrowSortedDown className="left-arrow" />
                            </div>
                            <div className={this.props.darkmode + " hidden-parent hobbies"}>
                                <div className="hobbies-child">
                                    <span className="hobbies-title">Gaming</span>
                                    <span className="hobbies-desc">
                                        I've been playing since I was a kid, I've mostly evolved around multiplayer games such as Minecraft and Counter-Strike, I've always been a very competitive player. Today, I'm trying to discover beautiful and interesting games that I may have missed during these years of tryhard.
                                        <br />
                                        <br />
                                        <u>Favorite licenses :</u>
                                        <ul>
                                            <li>Pokémon</li>
                                            <li>Minecraft</li>
                                            <li>Counter-Strike</li>
                                            <li>League of Legends</li>
                                        </ul>
                                        </span>
                                </div>
                                <div className="hobbies-child">
                                    <span className="hobbies-title">Manga/Anime</span>
                                    <span className="hobbies-desc">
                                    One of my greatest passions, it motivates me in my daily life and I followed a lot of advice from my favorite heroes to get to where I am. I have a very large manga collection with more than a thousand manga and a lot of figurines. I also watch anime when I have time.
                                    <br />
                                    <br />
                                    <u>Favorite series :</u>
                                    <ul>
                                    <li>Naruto</li>
                                    <li>Attack On Titan</li>
                                    <li>Your Lie In April </li>
                                    </ul>
                                    </span>
                                </div>
                                <div className="hobbies-child">
                                    <span className="hobbies-title">Photography</span>
                                    <span className="hobbies-desc">
                                        I started at the end of 2021 to get interested in photography. I bought a video camera to shoot my girlfriend who is an online content creator. 
                                        <br /> 
                                        I started to get interested in the best way to shoot, how to compose an image, a video, the light, the sound etc... I don't have the money to buy a photo camera (like a reflex) yet, so I borrow the one of my relatives to practice and I love it ! 
                                    </span>
                                </div>
                                <div className="hobbies-child">
                                    <span className="hobbies-title">Create Digital Content / Computer graphic</span>
                                    <span className="hobbies-desc">
                                        At 8 years old I took drawing lessons. When I was 10 years old I started to do video editing of my video games. At 13 years old I was making 3D minecraft character animations on Cinema 4D. Also, I had several eras where I was doing lives streams on Twitch. I often had the opportunity to make logo, banner, intro clip, graphic charter, for myself, friends or associations where I was. I bought a graphic tablet in 2020 where I started to do digital drawing. I often record, edit, draw miniatures, profile picture, photo editing, for my content creator girlfriend.
                                        <br />
                                        <br />
                                        In 2022 I started to learn 3D on Blender in parallel of the courses, I tried to do VFX on after effect for a personnal project.
                                        <br />
                                        <br />
                                        In short, I love learning to create, I am very curious and today my passion is useful in my projects.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Profile);