import React from 'react';
import { withRouter } from '../withRouter';
import { Navigate } from 'react-router-dom'
import "../Styles/Project.scss"

import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight, HiOutlineX } from "react-icons/hi"

import ApiContext from '../Tools/ApiClass';

class Project extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            content:[],
            redirect:false,
        }
    }

    componentDidMount = () => {
        this.fetchContent();
        
    }

    fetchContent = async () => {
        console.log(this.props.params)
        let data = await new ApiContext().GetDevProject(this.props.params.id);
        this.setState({content:data})
    }


    render() {
        if (this.state.content.length == 0) {
            return (
                <div id="Project" className={this.props.darkmode + " "}>
                    <span><i>Loading</i></span>
                </div>
            )
        }
        else if(this.state.redirect){
            return (
                <Navigate to="/projects" />
                )
            }
        else {
            console.log(this.state);
            return (
                <div id="Project" className={this.props.darkmode + " active"}>
                    <div className="back-arrow" onClick={()=>{this.setState({redirect:true})}}>
                        <span><HiChevronLeft />BACK</span>
                    </div>
                    <div className="project-content">
                        <h1>{this.state.content.nom}</h1>
                        <div className="tags">
                            {this.state.content.categories.map(e => <span key={e.nom+"tagsid"+this.state.content.nom+"project-unique"} style={{ backgroundColor: e.color }}>{e.nom}</span>)}
                        </div>
                        <div dangerouslySetInnerHTML={{__html :this.state.content.content}}>
                        </div>
                    </div>
                </div>
            )

        }
    }
}

export default withRouter(Project);