import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Art.scss"




class NoMobile extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            indexActive:0,
        }
    }

    

    render() {
        return(
            <div id="NoMobile" className={this.props.darkmode}>         
                <h1>The mobile version is not yet finished... Try it on PC !</h1>
            </div>
        )
    }


}

export default withRouter(NoMobile);