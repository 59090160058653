import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Home.scss"

import AppContext from '../Tools/AppContext';



class SwitchThemeMode extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            checkbox: false,
            firstLoadDarkmode: false,
        }
    }

    componentDidMount = () => {
        if(this.props.darkmode == "dark"){
            this.setState({checkbox: true})
        }
        else if(this.props.darkmode != null){
            this.setState({firstLoadDarkmode: true})
        }
        else
        {
            this.setState({firstLoadDarkmode: false})
        }
    }

    componentDidUpdate = () => {
        if(this.state.firstLoadDarkmode == false){
            if(this.props.darkmode == "dark"){
                this.setState({checkbox: true})
            }
            if(this.props.darkmode != null){
                this.setState({firstLoadDarkmode: true})
            }
        }
    }

    checkBox = () => {
        this.setState({
            checkbox: !this.state.checkbox
        }, () => {this.props.toggleTheme(this.state.checkbox)})
    }

    render() {
        return(
            <div id="Switch-theme-mode" className={this.props.darkmode}>
                <span className="label-switch">Darkmode</span>
                <label className="switch">
                    <input type="checkbox" checked={this.state.checkbox} onChange={this.checkBox} />
                    <span className="slider" />
                </label>
            </div>
        )
    }
}

export default withRouter(SwitchThemeMode);