import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Art.scss"

import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight, HiOutlineX } from "react-icons/hi"

import AppContext from '../Tools/AppContext';

const request = {
    id : 0,
    name : "Le petite rossignol",
    desc : "Elit sit officia eiusmod dolor ipsum cillum ipsum elit officia occaecat. Nulla labore dolore est nisi minim pariatur. Id sint quis occaecat et ad incididunt.",
    tags : [{id:0, name:"coucou"}],
    pictures : ["https://lemagdesanimaux.ouest-france.fr/images/dossiers/2022-07/rossignol-japon-171356.jpg", "https://www.notrenature.be/media/cache/strip/uploads/media/608577f00e6d7/kopie-van-wasbeer-fr-copie.png"]
}

class Art extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            indexActive:0,
        }
    }

    componentDidMount = () => {
    }


    NextSlider = () => {
        let imgList = document.getElementById("carousel-art").getElementsByClassName("img-container");
        let nextIndex = 0;
        if(this.state.indexActive == imgList.length-1)
        {
            nextIndex = 0;
        }
        else
        {
            nextIndex = this.state.indexActive + 1;
        }
        this.setState({indexActive: nextIndex})
    }
    PreviousSlider = () => {
        let imgList = document.getElementById("carousel-art").getElementsByClassName("img-container");
        let nextIndex = 0;
        if(this.state.indexActive == 0)
        {
            nextIndex = imgList.length-1;
        }
        else
        {
            nextIndex = this.state.indexActive -1;
        }
        this.setState({indexActive: nextIndex})

    }

    SetSlider = (event) => {
        event.preventDefault()
        this.setState({indexActive: event.currentTarget.dataset.id})
    }

    CloseWindow = () => {
        this.props.openPage(-1)
    }

    CloseWindowBackground = (event) => {
        if(event.target == event.currentTarget)
        {
            this.CloseWindow()
        }
    }

    PreviousPost = () => {
        this.props.openPage(this.state.data.id - 1)
    }
    NextPost = () => {
        this.props.openPage(this.state.data.id + 1)
    }

    render() {
        if(this.props.id == -1)
        {
            return;
        }
        return(
            <div id="Art" className={this.props.darkmode}>         
                <div className="art-container" onClick={(event)=>this.CloseWindowBackground(event)}>
                    <div className="art-content">
                        <div className="navigation">
                            <div className="navigation-arrow">
                                <span className="close-button" onClick={this.CloseWindow}><HiOutlineX /></span>
                            </div>
                        </div>
                        <div className="header-with-pictures">
                            <div className="left-content">
                                <h2>{this.props.data.info.nom}</h2>
                                <span>{this.props.data.info.post_date}</span>
                                <p dangerouslySetInnerHTML={{__html :this.props.data.info.description}}></p>
                            </div>
                            <div className="pictures-container" id="carousel-art">
                                <span onClick={this.PreviousSlider} className="left-arrow arrow-carousel"><HiChevronDoubleLeft /></span>
                                <span onClick={this.NextSlider}className="right-arrow arrow-carousel"><HiChevronDoubleRight /></span>
                                {this.renderImages()}
                                <div className="preview">
                                {this.renderPreview()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderImages = ( ) => {
        let display = []
        let index = 0;
        console.log(this.props)
        this.props.data["medias"].forEach(picture => {
            console.log(picture)
            if(picture.youtube == "1"){
                display.push(
                    <div key={picture.id + "big-screen"} className={index == this.state.indexActive?"active img-container":" img-container"} >
                        <div className="video-wrapper" dangerouslySetInnerHTML={{__html :picture.url}}>

                        </div>
                    </div>
                )
            }
            else if(picture.video == "1")
            {
                display.push(
                    <div key={picture.id+ "big-screen"} className={index == this.state.indexActive?"active img-container":" img-container"}>
                        <video controls>
                            <source src={picture.url} type="video/mp4"/>

                        </video>
                    </div>
                )
            }
            else{
                display.push(
                    <div key={picture.id+ "big-screen"} className={index == this.state.indexActive?"active img-container":" img-container"}>
                        <div className="img-bg" style={{backgroundImage:"url("+ "'" +  picture.url + "'" +")"}}></div>
                    </div>
                )

            }
            index++
        });
        return display
    }
    renderPreview = () => {
        let display = []
        
        let index = 0;
        index = 0;
        this.props.data["medias"].forEach(picture => {
            if(picture.video == "1" || picture.youtube == "1")
            {
                display.push(
                    <div onClick={(e)=>this.SetSlider(e)} key={picture.id +"minia"} data-id={index} className={index == this.state.indexActive?"active img-preview":" img-preview"}>
                        <img className="img-bg" src={"https://api.4lexis.fr/img/portefolio/video-minia.png"} ></img>
                    </div>
                )
            }
            else
            {
                display.push(
                    <div onClick={(e)=>this.SetSlider(e)} key={picture.id +"minia"} data-id={index} className={index == this.state.indexActive?"active img-preview":" img-preview"}>
                        <img className="img-bg" src={picture.url} ></img>
                    </div>
                )
            }
            index++
        });
        return display
    }
}

export default withRouter(Art);