import {
	BrowserRouter,
	Routes,
	Route
} from "react-router-dom"
import React from 'react';
import "./Styles/App.scss"


import Home from "./Pages/Home"
import Arts from "./Pages/Arts"
import Art from "./Pages/Art"
import Profile from "./Pages/Profile"
import Projects from "./Pages/Projects"
import Project from "./Pages/Project"
import NoMobile from "./Pages/NoMobile";

import Footer from "./Components/Footer"
import Header from "./Components/Header"
import LookingForJob from "./Components/LookingForJob"
import SwitchThemeMode from "./Components/SwitchThemeMode";


import { AppProvider } from "./Tools/AppContext";
import { withRouter } from "./withRouter";

class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			darkmode: null,
		}
	}

	componentDidMount = () => {
		this.loadTheme()
	}

	loadTheme = () => {
		if (this.state.darkmode == null) {
			let darkmode = localStorage.getItem("darkmode");
			if (darkmode == null) {
				localStorage.setItem("darkmode", "light")
				darkmode = "light"
			}
			this.setState({ darkmode: darkmode })
		}
	}
	toggleTheme = (booleanDarkmode) => {
		this.setState({ darkmode: booleanDarkmode ? "dark" : "light" })
		localStorage.setItem("darkmode", booleanDarkmode ? "dark" : "light")
	}

	render() {
		var width = window.innerWidth;
		if(width < 1080)
		{
			return <NoMobile />
		}
		return (
			<>
				<Header darkmode={this.state.darkmode} {...this.props} />
				<LookingForJob darkmode={this.state.darkmode} {...this.props} />
				<SwitchThemeMode darkmode={this.state.darkmode} toggleTheme={this.toggleTheme}  />
				<Routes>
					<Route exact path="/" element={<Home darkmode={this.state.darkmode} />}  />
					<Route exact path="/projects" element={<Projects darkmode={this.state.darkmode} />}  />
					<Route exact path="/project/:id" element={<Project darkmode={this.state.darkmode} />}  />
					<Route exact path="/arts" element={<Arts darkmode={this.state.darkmode} />} />
					<Route exact path="/arts/:id" element={<Art darkmode={this.state.darkmode} />} />
					<Route exact path="/profile" element={<Profile darkmode={this.state.darkmode} />}  />
				</Routes>
				<Footer />
			</>
		);
	}
}

export default withRouter(App);
