import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Home.scss"
import TagsManager from './TagsManager';

import ApiContext from '../Tools/ApiClass';


class ArtTab extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            tags_id: [],
            dataCategories: [],
            dataProjects: [],
        }
    }

    componentDidMount = () => {
        this.fetchCategories()
    }

    fetchCategories = async () => {
        let data = await new ApiContext().GetArtCategories()
        this.setState({dataCategories:data})
    }

    fetchProjects = async () => {
        let data = await new ApiContext().GetArtProjects(this.state.tags_id)
        this.setState({dataProjects:data})
    }


    setTags = (tags) => {
        this.setState({
            tags_id: tags
        }, () => {
            this.fetchProjects();
        })
    }

    openPage = (event) => {
        this.props.openPage(event.currentTarget.dataset.id)
    }
 
    render() {
        return(
            <div id="Art-tab" className={this.props.darkmode}>
                <div className="header-tab">
                    <span className="tags-name">FILTER : </span>
                    <TagsManager tags={this.state.dataCategories} setTags={this.setTags} />
                </div>
                <div className="gallery">
                    {this.renderGallery()}
                </div>
            </div>
        )
    }
    renderGallery = () => {
        let display = []
        for(var picture in this.state.dataProjects){
            display.push(
                <div className="picture-container" data-id={this.state.dataProjects[picture].id} key={this.state.dataProjects[picture].id+"pictureediting"} onClick={(e)=>this.openPage(e)}>
                    <div className="picture-content" >
                        <div className="img-background" style={{backgroundImage:"url("+ "'" +  this.state.dataProjects[picture].url + "'" +")"}}>
                            <div className="filter"></div>
                        </div>
                        <span className="title">{this.state.dataProjects[picture].nom}</span>
                    </div>
                </div>
            )
        }
        return display
    }
}

export default withRouter(ArtTab);