import React from 'react';
import { withRouter } from '../withRouter';

class Footer extends React.Component{
    render() {
        return(
            <></>
        )
    }
}

export default withRouter(Footer);