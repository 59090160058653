import React from 'react';
import AppContext from '../Tools/AppContext';
import { withRouter } from '../withRouter';
import {Link} from "react-router-dom"
import { useLocation } from 'react-router-dom';

import { AiFillHome } from "react-icons/ai"

import "../Styles/Header.scss"

class Header extends React.Component{
    constructor(props){
        super(props)
    }
    componentDidMount(){
        this.findActiveTab()
    }

    componentDidUpdate(){
        this.findActiveTab()
    }

    findActiveTab = () => {
        let header = document.getElementById("Header")
        let links = header.getElementsByTagName("a")
        for (let index = 0; index < links.length; index++) {
            const element = links[index];
            if(element.classList.contains("active")){
                element.classList.remove("active")
            }
        }
        for (let index = 0; index < links.length; index++) {
            const element = links[index];
            if(element.attributes[1].value == "/"+ window.location.pathname.split("/")[1]){
                element.classList.add("active")
            }
        }
    }

    render() {
        return(
            <div id="Header" className={this.props.darkmode}>
                <div className="header-list-container" onClick={this.findActiveTab}>
                    <Link to="/" className="list-element"><div className="dot" /><span>Home</span></Link>
                    <Link to="/projects" className="list-element"><div className="dot" /><span>Projects</span></Link>
                    <Link to="/arts" className="list-element"><div className="dot" /><span>Arts</span></Link>
                    <Link to="/profile" className="list-element"><div className="dot" /><span>Profile</span></Link>
                </div>
            </div>
        )
    }
}

export default withRouter(Header);