import React from 'react';
import { withRouter } from '../withRouter';

import "../Styles/Home.scss"
import ApiContext from '../Tools/ApiClass';


class AboutArtTab extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            content: [],
        }
    }
    componentDidMount = async () => {
        this.setState({
            content: await new ApiContext().GetContent("about_art"),
        },()=>console.log(this.state))
    }

    

    render() {
        return(
            <div id="About-art-tab" className={this.props.darkmode}>
                <div className="container-component">
                    <h1>{this.state.content.titre}</h1>
                    <div dangerouslySetInnerHTML={{__html :this.state.content.content}} />
                </div>
            </div>
        )
    }
}

export default withRouter(AboutArtTab);